import { ReactNode, createContext, useEffect, useState, useContext, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { fetchUserPreferences, createUserPreferences } from 'src/api/userPreferences';
import { datadogRum } from '@datadog/browser-rum';
import { snackbarContext } from 'src/wrappers/SnackBarWrapper';
import { AuthData, getUserAuthData } from '../api/user/user';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setUserDetails as setUserDetailState, fetchAllAccountItem, fetchAccountDetails } from '../slices/accountSlice';
import { fetchBuildingColor, fetchBuildingItems, fetchFavItems } from '../slices/buildingSlice';
import { errorInfoSelector, resetGlobalError, setDarklyFeaturesData } from '../slices/commonSlice';
import { fetchAllRoles } from '../slices/externalUserRoleSlice';
import appLogger from '../infrastructure/config/appLogger';
import { fetchLoggedInUser } from '../slices/externalUserSlice';
import { fetchVideoCategoryItems, fetchVideoItems } from '../slices/resourcesSlice';
import {
  USER_STATUS_NEW,
  USER_STATUS_ACTIVE,
  USER_STATUS_REJECTED,
  USER_STATUS_INACTIVE,
  PRELOAD_DARKLY_FEATURES,
  FETCH_ROLES_PAGE_SIZE,
} from '../constants/common';
import LandingLayout from '../components/LandingLayout';
import { storage } from '../api/base';
import DialogWrapper from '../components/modals/DialogWrapper';
import LandingDisclaimer from '../components/LandingDisclaimer';
import TopBar from '../components/headers/TopBar';
import { getBooleanFeatureFlagValue } from '../utils/LaunchDarklyQuery';
import BackDropSpinner from '../components/spinner/BackDropSpinner';
import { PRACTICE_STUDENT } from '../components/roles/constants/role_data';
import AgGridTour from './AgGridTour';
import { getStudentsByAccount } from '../slices/studentSlice';

interface UserTypeContext {
  user: AuthData | null;
}

interface LoadingState {
  isLoading: boolean;
}

interface Props {
  children: ReactNode;
}

interface ContextResult extends UserTypeContext {
  setUserDetails: (user: AuthData | null) => void;
  refreshUserDetails: () => Promise<null | AuthData>;
  getCurrentAuthData: () => Promise<null | AuthData>;
}

export const UserContext = createContext<ContextResult>({
  getCurrentAuthData(): Promise<AuthData | null> {
    return Promise.resolve(null);
  },
  user: null,
  setUserDetails: () => null,
  refreshUserDetails(): Promise<AuthData | null> {
    return Promise.resolve(null);
  },
});

type CurrentState = UserTypeContext &
  LoadingState & {
    isError?: boolean;
  };
const disclaimerPreferenceKey = 'termsOfService';
const agGridTourModalKey = 'agGridTourModal';
const sessionAgGridTourValue = localStorage.getItem(agGridTourModalKey);

const UserDetailWrapper = ({ children }: Props) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const errorSelector = useAppSelector(errorInfoSelector);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { setSnackBar } = snackbarContext();
  const [state, setState] = useState<CurrentState>({
    user: null,
    isLoading: true,
    isError: false,
  });
  const [showDisclaimerContent, enableDisclaimerContent] = useState<boolean>(false);
  const [agGridTourModal, setAgGridTourModal] = useState<boolean>(false);

  useEffect(() => {
    const result = PRELOAD_DARKLY_FEATURES.map(async (featureName: string) => {
      const value = await getBooleanFeatureFlagValue(featureName);
      const newValue = isString(value) ? value === 'on' : value;
      appLogger.log('Feature Loaded', featureName, value);
      return {
        feature: featureName,
        value: newValue,
      };
    });
    Promise.all(result).then((featureValues) => {
      const featureFlags = {};
      featureValues.forEach((value) => {
        featureFlags[value.feature] = value.value;
      });
      dispatch(setDarklyFeaturesData(featureFlags));
    });
  }, []);

  async function getCurrentAuthData(): Promise<AuthData> {
    const spoofingUser = await storage.getKey('spoofingUserDetails');
    let token = await getAccessTokenSilently();
    if (spoofingUser?.isSpoofing) {
      token = spoofingUser?.token;
    }
    const authData = await getUserAuthData(token);
    const userStatusValue = get(authData, 'user_status') || 'Active';
    const newData = {
      ...authData,
      user_status: userStatusValue,
      is_gac: get(authData, 'is_gac', false),
      is_new: isEqual(userStatusValue, USER_STATUS_NEW),
      has_token: get(authData, 'has_token'),
      isPractiseStudent: get(authData, 'role_name') === PRACTICE_STUDENT,
      token,
    };
    if (userStatusValue === USER_STATUS_ACTIVE) {
      const accountId = get(newData, 'account') || '';
      dispatch(fetchFavItems({ userId: authData.logged_in_id, token }));
      dispatch(fetchAllRoles({ token, page: FETCH_ROLES_PAGE_SIZE }));
      const loggedInData = await dispatch(fetchLoggedInUser({ userEmail: authData.email, token }));
      const addedBuilding = get(loggedInData, 'payload.building') || 0;
      dispatch(fetchBuildingItems({ token }));
      dispatch(fetchAllAccountItem({ token }));
      dispatch(fetchAccountDetails({ emailAddress: authData.email, token }));
      dispatch(fetchVideoItems({ token }));
      dispatch(fetchVideoCategoryItems({ token }));
      dispatch(getStudentsByAccount({ token, accountNumber: accountId }));
      if (authData.account) {
        dispatch(fetchFavItems({ userId: authData.logged_in_id, token }));
      }
      if (addedBuilding > 0) {
        dispatch(fetchBuildingColor({ itemId: addedBuilding, token }));
      }
    }
    dispatch(setUserDetailState(newData));
    setState((prevState) => ({
      ...prevState,
      user: newData,
    }));
    return newData;
  }

  const checkUserAgreementStatus = async (preferenceKey: string, token: string) => {
    try {
      const response = await fetchUserPreferences({ preferenceKey, token });
      return response?.data?.preference_value.acceptance;
    } catch (error) {
      datadogRum.addError(error, {
        context: 'CheckingAgreementStatus',
        message: 'Failed to check user agreement status',
        ddTags: 'agreementStatus',
      });
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated && state.user === null) {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        try {
          const userData = await getCurrentAuthData();
          appLogger.log('Loaded', userData);
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
          if (userData.user_status === USER_STATUS_ACTIVE) {
            const token = await getAccessTokenSilently();
            const hasAcceptedTerms = !!(await checkUserAgreementStatus(disclaimerPreferenceKey, token));
            setOpenModal(!hasAcceptedTerms);

            const hasAgGridTourModal = await getBooleanFeatureFlagValue('ag-grid-tour-modal');
            if (sessionAgGridTourValue === null && hasAgGridTourModal) {
              setAgGridTourModal(true);
            } else {
              setAgGridTourModal(false);
            }
          }
        } catch (error) {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
          }));
        } finally {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    };

    fetchData();
  }, [isAuthenticated, getAccessTokenSilently, state.user]);

  useEffect(() => {
    if (openModal) {
      setTimeout(() => {
        enableDisclaimerContent(true);
      }, 2000);
    }
  }, [openModal]);

  const values = useMemo(
    () => ({
      ...state,
      setUserDetails: (user: AuthData | null): void => {
        if (user !== null) {
          dispatch(setUserDetailState(user));
          setState((prevState) => ({
            ...prevState,
            user,
          }));
        }
      },
      refreshUserDetails: async () => getCurrentAuthData(),
      getCurrentAuthData,
    }),
    [state]
  );

  const handleCloseError = () => {
    dispatch(resetGlobalError());
  };

  const refreshAuth = async () => {
    try {
      await getCurrentAuthData();
    } catch (e) {
      appLogger.log('Error', e);
    }
  };

  const handleDisclaimerClose = async () => {
    enableDisclaimerContent(false);
    const token = await getAccessTokenSilently();
    const body = { acceptance: true, date: new Date().toISOString() };
    try {
      await createUserPreferences({
        preferenceKey: disclaimerPreferenceKey,
        preferenceValue: body,
        token,
      });

      setSnackBar({
        message: 'Agreement accepted successfully',
        success: true,
        open: true,
      });
    } catch (error) {
      setSnackBar({
        message: 'Failed to accept the agreement. Please try again.',
        success: false,
        open: true,
      });
      datadogRum.addError(error, {
        context: 'UserAgreement',
        message: 'Failed to create user agreement preference',
        ddTags: 'agreementStatus',
      });
    }
  };

  return (
    <UserContext.Provider value={values}>
      <TopBar />
      {errorSelector.hasError && (
        <Snackbar
          open={errorSelector.hasError}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="error" onClose={handleCloseError}>
            <AlertTitle>Error</AlertTitle>
            {errorSelector.error}
          </Alert>
        </Snackbar>
      )}
      {state.isLoading && <BackDropSpinner open={state.isLoading} />}
      {state.user !== null && (
        <>
          {state.user.user_status === USER_STATUS_ACTIVE && children}
          {[USER_STATUS_NEW, USER_STATUS_REJECTED, USER_STATUS_INACTIVE].includes(state.user.user_status) && (
            <LandingLayout user={state.user} refreshAuth={refreshAuth} />
          )}
        </>
      )}
      {state.isError && <LandingLayout user={state.user} isError refreshAuth={refreshAuth} />}
      <DialogWrapper
        isFullScreen
        handleClose={handleDisclaimerClose}
        open={showDisclaimerContent}
        modalActionPostition="center"
      >
        <LandingDisclaimer />
      </DialogWrapper>
      <AgGridTour open={agGridTourModal} agGridTourModalKey={agGridTourModalKey} />
      {!isAuthenticated && children}
    </UserContext.Provider>
  );
};

export const useUserDetails = () => useContext(UserContext);

export default UserDetailWrapper;
