import React, { useLayoutEffect, useCallback } from 'react';
import { get, isEmpty } from 'lodash';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import ErrorCard from './divs/ErrorCard';
import { AuthData } from '../api/user/user';
import { USER_STATUS_INACTIVE, USER_STATUS_REJECTED } from '../constants/common';

const theme = createTheme();

interface Props {
  user: AuthData | null;
  isError?: boolean;
  refreshAuth: () => Promise<void>;
}

const LandingLayout = ({ user, isError = false, refreshAuth }: Props) => {
  const isUserAllowed = !!get(user, 'allow_user', false);
  const { isAuthenticated, logout } = useAuth0();
  const handleLogoutPromise = useCallback(() => {
    if (isAuthenticated) {
      logout({
        returnTo: '/',
      } as Omit<LogoutOptions, 'onRedirect'>).then(() => null);
    }
  }, [isAuthenticated]);
  useLayoutEffect(() => {
    if (!isEmpty(user) && !isUserAllowed) {
      setTimeout(() => {
        handleLogoutPromise();
      }, 5000);
    }
  }, [user]);

  const handleLogout = (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleLogoutPromise();
  };

  const deleteAnchor = isAuthenticated ? (
    <Tooltip title="You will be redirected back to the home page" arrow>
      <Button type="button" onClick={handleLogout}>
        Login Again?
      </Button>
    </Tooltip>
  ) : null;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Dream
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          {!isEmpty(user) && isUserAllowed && user.user_status !== USER_STATUS_INACTIVE ? (
            <>
              {isError ? (
                <Alert severity="error">An error occurred!</Alert>
              ) : (
                <Typography component="h1" variant="h4" align="center">
                  Request Sent
                </Typography>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box>
                  {isError ? (
                    <ErrorCard refreshAuth={refreshAuth} />
                  ) : (
                    <>
                      <Typography variant="h5" gutterBottom sx={{ mt: 5 }}>
                        Thank you for requesting to create an account.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom sx={{ mt: 5 }}>
                        Please contact the Vivacity Tech PBC team representative for further steps.{' '}
                      </Typography>

                      {get(user, 'user_status') === USER_STATUS_REJECTED && (
                        <Typography variant="subtitle1">Your request to access Dream has been rejected.</Typography>
                      )}
                      <ButtonGroup aria-label="Basic button group" sx={{ display: 'flex', flex: 1, mt: 3 }}>
                        <Button onClick={refreshAuth} variant="contained">
                          Refresh
                        </Button>
                        {deleteAnchor}
                      </ButtonGroup>
                    </>
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Alert severity="error">Unauthorized Access!</Alert>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box>
                  <Typography variant="h5" gutterBottom sx={{ mt: 5 }}>
                    You are not allowed to login in this environment, redirecting you to homepage in a moment
                  </Typography>
                  {deleteAnchor}
                </Box>
              </Box>
            </>
          )}
          {isEmpty(user) && isError && (
            <>
              <Alert severity="error">An error occurred!</Alert>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box>
                  <ErrorCard refreshAuth={refreshAuth} />
                </Box>
              </Box>
            </>
          )}
        </Paper>
        <Typography variant="body2" color="text.secondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://vivacitytech.com/">
            Vivacity Tech PBC
          </Link>{' '}
          {new Date().getFullYear()}.
        </Typography>
      </Container>
    </ThemeProvider>
  );
};

export default LandingLayout;
