import {
  REPAIR_360,
  USERS,
  USER,
  ACCOUNT_DASHBOARD,
  REPORT_CENTER,
  MANAGE_DEVICES,
  RESOURCES,
  GOOGLE_ADMIN,
  MY_BUILDINGS,
  MY_PROFILE,
  SETTINGS,
  FEATURES,
  DASHBOARD_COLOR,
  VIVACITY_ADMIN_PAGE,
  SIDEBAR,
  SUPER_ADMIN,
  STUDENT,
  BUILDING_ADMIN,
  REPAIR_TECH,
  FACILITATOR,
  SUPER_STUDENT,
  SCHOOL_DISTRICT_ADMIN,
  ADVANCED_STUDENT,
  VIVACITY_ADMIN,
  ROLE_ACTIONS,
  SUPPORT,
  STUDENT_REPAIR_TECHNICIAN,
  LOANER_MANAGER,
  PRACTICE_STUDENT,
  INVOICES,
  ACCOUNT_CREDITS,
  VIVACITY_TECHNICIAN,
  HEADER_MENU,
} from './role_data';
import {
  REPAIR_360_SUB_MODULE_MAP,
  USER_SUB_MODULE_MAP,
  RESOURCES_SUB_MODULE_MAP,
  REPORT_CENTER_SUB_MODULE_MAP,
  MANAGE_DEVICES_SUB_MODULE_MAP,
  MY_PROFILE_SUB_MODULE_MAP,
  FEATURES_SUB_MODULE_MAP,
  SUPPORT_SUB_MODULE_MAP,
  ACCOUNT_DASHBOARD_SUB_MODULE_MAP,
  SIDEBAR_SUB_MODULE_MAP,
  INVOICES_SUB_MODULE_MAP,
  HEADER_MENU_SUB_MODULE_MAP,
} from './sub_modules';

export const PAGE_NAMES = {
  REPAIR_360,
  USERS,
  USER,
  ACCOUNT_DASHBOARD,
  REPORT_CENTER,
  MANAGE_DEVICES,
  RESOURCES,
  GOOGLE_ADMIN,
  MY_BUILDINGS,
  MY_PROFILE,
  SETTINGS,
  FEATURES,
  DASHBOARD_COLOR,
  VIVACITY_ADMIN_PAGE,
  SIDEBAR,
  SUPPORT,
  INVOICES,
  ACCOUNT_CREDITS,
  VIVACITY_TECHNICIAN,
  HEADER_MENU,
};

const allRoles = [
  SUPER_ADMIN,
  STUDENT,
  BUILDING_ADMIN,
  REPAIR_TECH,
  FACILITATOR,
  SUPER_STUDENT,
  SCHOOL_DISTRICT_ADMIN,
  ADVANCED_STUDENT,
  VIVACITY_ADMIN,
  STUDENT_REPAIR_TECHNICIAN,
  LOANER_MANAGER,
  PRACTICE_STUDENT,
];

export const ALL_ACTIONS = [
  ROLE_ACTIONS.CREATE,
  ROLE_ACTIONS.VIEW,
  ROLE_ACTIONS.MODIFY,
  ROLE_ACTIONS.CREATE_STUDENT,
  ROLE_ACTIONS.CHECK_IN_OUT,
  ROLE_ACTIONS.CREATE_TICKET,
];
const ACTION_VIEW = [ROLE_ACTIONS.VIEW];
const ACTION_VIEW_MODIFY = [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.MODIFY];
const ACTION_VIEW_CREATE = [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.CREATE];

export const VIEW_PAGE_ROLES = {
  [PAGE_NAMES.ACCOUNT_DASHBOARD]: [
    SUPER_ADMIN,
    SCHOOL_DISTRICT_ADMIN,
    BUILDING_ADMIN,
    REPAIR_TECH,
    FACILITATOR,
    LOANER_MANAGER,
    VIVACITY_ADMIN,
  ],
  [PAGE_NAMES.REPAIR_360]: allRoles,
  [PAGE_NAMES.REPORT_CENTER]: [
    SUPER_ADMIN,
    BUILDING_ADMIN,
    REPAIR_TECH,
    SUPER_STUDENT,
    SCHOOL_DISTRICT_ADMIN,
    VIVACITY_ADMIN,
  ],
  [PAGE_NAMES.MANAGE_DEVICES]: [
    SUPER_ADMIN,
    BUILDING_ADMIN,
    REPAIR_TECH,
    FACILITATOR,
    SUPER_STUDENT,
    SCHOOL_DISTRICT_ADMIN,
    ADVANCED_STUDENT,
    VIVACITY_ADMIN,
    STUDENT_REPAIR_TECHNICIAN,
    LOANER_MANAGER,
  ],
  [PAGE_NAMES.RESOURCES]: allRoles,
  [PAGE_NAMES.MY_BUILDINGS]: [
    SUPER_ADMIN,
    SCHOOL_DISTRICT_ADMIN,
    BUILDING_ADMIN,
    REPAIR_TECH,
    FACILITATOR,
    LOANER_MANAGER,
    VIVACITY_ADMIN,
  ],
  [PAGE_NAMES.MY_PROFILE]: allRoles.concat(VIVACITY_TECHNICIAN),
  [PAGE_NAMES.SETTINGS]: [
    SUPER_ADMIN,
    SCHOOL_DISTRICT_ADMIN,
    BUILDING_ADMIN,
    REPAIR_TECH,
    FACILITATOR,
    VIVACITY_ADMIN,
    STUDENT_REPAIR_TECHNICIAN,
    SUPER_STUDENT,
    LOANER_MANAGER,
  ],
  [PAGE_NAMES.FEATURES]: [
    SUPER_ADMIN,
    BUILDING_ADMIN,
    REPAIR_TECH,
    FACILITATOR,
    SUPER_STUDENT,
    SCHOOL_DISTRICT_ADMIN,
    ADVANCED_STUDENT,
    VIVACITY_ADMIN,
    STUDENT_REPAIR_TECHNICIAN,
    LOANER_MANAGER,
    PRACTICE_STUDENT,
  ],
  [PAGE_NAMES.DASHBOARD_COLOR]: [SUPER_ADMIN, SCHOOL_DISTRICT_ADMIN, BUILDING_ADMIN, VIVACITY_ADMIN],
  [PAGE_NAMES.USERS]: [
    SUPER_ADMIN,
    VIVACITY_ADMIN,
    FACILITATOR,
    BUILDING_ADMIN,
    SCHOOL_DISTRICT_ADMIN,
    REPAIR_TECH,
    LOANER_MANAGER,
  ],
  [PAGE_NAMES.USER]: [
    SUPER_ADMIN,
    VIVACITY_ADMIN,
    FACILITATOR,
    BUILDING_ADMIN,
    SCHOOL_DISTRICT_ADMIN,
    REPAIR_TECH,
    LOANER_MANAGER,
  ],
  [PAGE_NAMES.VIVACITY_ADMIN_PAGE]: [VIVACITY_ADMIN],
  [PAGE_NAMES.INVOICES]: [SUPER_ADMIN, VIVACITY_ADMIN, SCHOOL_DISTRICT_ADMIN, BUILDING_ADMIN],
  [PAGE_NAMES.ACCOUNT_CREDITS]: [SUPER_ADMIN, SCHOOL_DISTRICT_ADMIN, BUILDING_ADMIN, VIVACITY_ADMIN],
  [PAGE_NAMES.VIVACITY_TECHNICIAN]: [VIVACITY_TECHNICIAN],
};

const repairRoles = {
  [SUPER_ADMIN]: ALL_ACTIONS,
  [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
  [BUILDING_ADMIN]: ALL_ACTIONS,
  [REPAIR_TECH]: ALL_ACTIONS,
  [VIVACITY_ADMIN]: ALL_ACTIONS,
  [SUPER_STUDENT]: ALL_ACTIONS,
};

export const ROLE_MAP = {
  [PAGE_NAMES.REPAIR_360]: {
    [REPAIR_360_SUB_MODULE_MAP.REPAIR_CENTER]: {
      ...repairRoles,
      [LOANER_MANAGER]: [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.CREATE_TICKET],
      [SUPER_STUDENT]: [],
    },
    [REPAIR_360_SUB_MODULE_MAP.IN_HOUSE_REPAIR_REPORTS]: {
      ...repairRoles,
      [LOANER_MANAGER]: ACTION_VIEW_CREATE,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW_CREATE,
      [ADVANCED_STUDENT]: [],
    },
    [REPAIR_360_SUB_MODULE_MAP.REPAIR_BOXES]: { ...repairRoles, [LOANER_MANAGER]: ACTION_VIEW_CREATE },
    [REPAIR_360_SUB_MODULE_MAP.REPAIR_TICKETS]: {
      ...repairRoles,
      [LOANER_MANAGER]: ACTION_VIEW_CREATE,
      [SUPER_STUDENT]: [],
    },
    [REPAIR_360_SUB_MODULE_MAP.PART_CLOSET]: {
      ...repairRoles,
      [SUPER_STUDENT]: [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.MODIFY],
    },
    [REPAIR_360_SUB_MODULE_MAP.STUDENT_REPAIR_CENTER]: {
      ...repairRoles,
      [FACILITATOR]: ALL_ACTIONS,
      [STUDENT_REPAIR_TECHNICIAN]: [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.CREATE_TICKET],
      [STUDENT]: ACTION_VIEW,
      [LOANER_MANAGER]: [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.CREATE_STUDENT, ROLE_ACTIONS.CREATE_TICKET],
      [PRACTICE_STUDENT]: [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.MODIFY, ROLE_ACTIONS.CREATE_TICKET],
    },
  },
  [PAGE_NAMES.USERS]: {
    [USER_SUB_MODULE_MAP.ORGANIZATIONAL_UNITS]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [FACILITATOR]: [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.MODIFY],
    },
    [USER_SUB_MODULE_MAP.USERS]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [FACILITATOR]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: [ROLE_ACTIONS.VIEW],
      [REPAIR_TECH]: [ROLE_ACTIONS.VIEW],
      [LOANER_MANAGER]: [ROLE_ACTIONS.VIEW],
    },
    [USER_SUB_MODULE_MAP.BULK_USER_ACTIONS]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [FACILITATOR]: [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.MODIFY],
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
    },
  },
  [PAGE_NAMES.RESOURCES]: {
    [RESOURCES_SUB_MODULE_MAP.INSTRUCTIONS]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [STUDENT]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [SUPER_STUDENT]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW,
      [PRACTICE_STUDENT]: ACTION_VIEW,
    },
    [RESOURCES_SUB_MODULE_MAP.WARRANTY_INFO]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [STUDENT]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [SUPER_STUDENT]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW,
      [PRACTICE_STUDENT]: ACTION_VIEW,
    },
    [RESOURCES_SUB_MODULE_MAP.HOW_TO_VIDEOS]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [STUDENT]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [SUPER_STUDENT]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW,
      [PRACTICE_STUDENT]: ACTION_VIEW,
    },
  },
  [PAGE_NAMES.REPORT_CENTER]: {
    [REPORT_CENTER_SUB_MODULE_MAP.VIVACITY_REPAIR_REPORT]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
    },
    [REPORT_CENTER_SUB_MODULE_MAP.IN_HOUSE_REPAIR_REPORTS]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
      [SUPER_STUDENT]: ACTION_VIEW,
    },
    [REPORT_CENTER_SUB_MODULE_MAP.DEVICE_REPAIR_HISTORY_REPORT]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
    },
    [REPORT_CENTER_SUB_MODULE_MAP.SRA_REPORTS]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [SUPER_STUDENT]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
      [PRACTICE_STUDENT]: ACTION_VIEW,
    },
    [REPORT_CENTER_SUB_MODULE_MAP.NON_WARRANTY_REPAIRS]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ALL_ACTIONS,
      [REPAIR_TECH]: ALL_ACTIONS,
      [FACILITATOR]: ALL_ACTIONS,
      [SUPER_STUDENT]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [PRACTICE_STUDENT]: ACTION_VIEW,
    },
    [REPORT_CENTER_SUB_MODULE_MAP.TELEMETRY_DATA]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
    },
    [REPORT_CENTER_SUB_MODULE_MAP.FLEET_VALUE]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
    },
  },
  [PAGE_NAMES.MY_PROFILE]: {
    [MY_PROFILE_SUB_MODULE_MAP.PROFILE_DETAILS]: {
      [SUPER_ADMIN]: ACTION_VIEW_MODIFY,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW_MODIFY,
      [BUILDING_ADMIN]: ACTION_VIEW_MODIFY,
      [REPAIR_TECH]: ACTION_VIEW_MODIFY,
      [FACILITATOR]: ACTION_VIEW_MODIFY,
      [SUPER_STUDENT]: ACTION_VIEW,
      [STUDENT]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW_MODIFY,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW_MODIFY,
      [PRACTICE_STUDENT]: ACTION_VIEW,
      [VIVACITY_TECHNICIAN]: ACTION_VIEW_MODIFY,
    },
    [MY_PROFILE_SUB_MODULE_MAP.ACCOUNT]: {
      [SUPER_ADMIN]: ACTION_VIEW_MODIFY,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW_MODIFY,
      [BUILDING_ADMIN]: ACTION_VIEW_MODIFY,
      [REPAIR_TECH]: ACTION_VIEW_MODIFY,
      [FACILITATOR]: ACTION_VIEW_MODIFY,
      [SUPER_STUDENT]: ACTION_VIEW,
      [STUDENT]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW_MODIFY,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW_MODIFY,
      [PRACTICE_STUDENT]: ACTION_VIEW,
      [VIVACITY_TECHNICIAN]: ACTION_VIEW,
    },
    [MY_PROFILE_SUB_MODULE_MAP.IMAGE]: {
      [SUPER_ADMIN]: ACTION_VIEW_MODIFY,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW_MODIFY,
      [BUILDING_ADMIN]: ACTION_VIEW_MODIFY,
      [REPAIR_TECH]: ACTION_VIEW_MODIFY,
      [FACILITATOR]: ACTION_VIEW_MODIFY,
      [SUPER_STUDENT]: ACTION_VIEW,
      [STUDENT]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW_MODIFY,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW_MODIFY,
      [PRACTICE_STUDENT]: ACTION_VIEW,
      [VIVACITY_TECHNICIAN]: ACTION_VIEW_MODIFY,
    },
    [MY_PROFILE_SUB_MODULE_MAP.NOTIFICATIONS]: {
      [SUPER_ADMIN]: ACTION_VIEW_MODIFY,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW_MODIFY,
      [BUILDING_ADMIN]: ACTION_VIEW_MODIFY,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW_MODIFY,
      [LOANER_MANAGER]: ACTION_VIEW,
      [VIVACITY_TECHNICIAN]: ACTION_VIEW_MODIFY,
    },
    [MY_PROFILE_SUB_MODULE_MAP.PREFERENCES]: {
      [SUPER_ADMIN]: ACTION_VIEW_MODIFY,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW_MODIFY,
      [BUILDING_ADMIN]: ACTION_VIEW_MODIFY,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW_MODIFY,
      [LOANER_MANAGER]: ACTION_VIEW,
      [VIVACITY_TECHNICIAN]: ACTION_VIEW_MODIFY,
    },
  },
  [PAGE_NAMES.MANAGE_DEVICES]: {
    [MANAGE_DEVICES_SUB_MODULE_MAP.MY_FLEET]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ALL_ACTIONS,
      [REPAIR_TECH]: ACTION_VIEW_MODIFY,
      [FACILITATOR]: ACTION_VIEW,
      [SUPER_STUDENT]: [ROLE_ACTIONS.VIEW],
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW,
    },
    [MANAGE_DEVICES_SUB_MODULE_MAP.MY_FLEET_CREATE_TICKET_360]: {
      // WARNING: This should have the same permissions as REPAIR_360_SUB_MODULE_MAP.REPAIR_CENTER
      ...repairRoles,
      [LOANER_MANAGER]: ACTION_VIEW_CREATE,
      [SUPER_STUDENT]: [],
    },
    [MANAGE_DEVICES_SUB_MODULE_MAP.LOANER_MANAGEMENT]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ALL_ACTIONS,
      [REPAIR_TECH]: ALL_ACTIONS,
      [FACILITATOR]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [LOANER_MANAGER]: [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.CHECK_IN_OUT],
    },
    [MANAGE_DEVICES_SUB_MODULE_MAP.IMPORT_SERIAL_DEVICES]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ALL_ACTIONS,
      [REPAIR_TECH]: ACTION_VIEW_MODIFY,
      [FACILITATOR]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [LOANER_MANAGER]: ACTION_VIEW,
    },
    [MANAGE_DEVICES_SUB_MODULE_MAP.ORGANIZATION_UPLOADED_ASSETS]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ALL_ACTIONS,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [REPAIR_TECH]: ACTION_VIEW_MODIFY,
      [FACILITATOR]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW,
    },
  },
  [PAGE_NAMES.FEATURES]: {
    [FEATURES_SUB_MODULE_MAP.FEATURE_REQUEST]: {
      [SUPER_ADMIN]: [ROLE_ACTIONS.CREATE, ROLE_ACTIONS.VIEW],
      [SCHOOL_DISTRICT_ADMIN]: [ROLE_ACTIONS.CREATE, ROLE_ACTIONS.VIEW],
      [BUILDING_ADMIN]: [ROLE_ACTIONS.CREATE, ROLE_ACTIONS.VIEW],
      [REPAIR_TECH]: [ROLE_ACTIONS.CREATE, ROLE_ACTIONS.VIEW],
      [FACILITATOR]: [ROLE_ACTIONS.CREATE, ROLE_ACTIONS.VIEW],
      [SUPER_STUDENT]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [STUDENT]: ACTION_VIEW,
      [VIVACITY_ADMIN]: [ROLE_ACTIONS.CREATE, ROLE_ACTIONS.VIEW],
      [LOANER_MANAGER]: [ROLE_ACTIONS.CREATE, ROLE_ACTIONS.VIEW],
      [PRACTICE_STUDENT]: ACTION_VIEW,
    },
    [FEATURES_SUB_MODULE_MAP.UNDER_CONSIDERATION]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW,
    },
    [FEATURES_SUB_MODULE_MAP.SCHEDULED]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW,
    },
    [FEATURES_SUB_MODULE_MAP.UNDER_CONSTRUCTION]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW,
    },
    [FEATURES_SUB_MODULE_MAP.LAUNCHED]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ACTION_VIEW,
      [LOANER_MANAGER]: ACTION_VIEW,
    },
  },
  [PAGE_NAMES.SUPPORT]: {
    [SUPPORT_SUB_MODULE_MAP.MAIN_PAGE]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [STUDENT]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ALL_ACTIONS,
      [REPAIR_TECH]: ALL_ACTIONS,
      [FACILITATOR]: ALL_ACTIONS,
      [SUPER_STUDENT]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [ADVANCED_STUDENT]: ALL_ACTIONS,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [LOANER_MANAGER]: ALL_ACTIONS,
    },
  },
  [PAGE_NAMES.ACCOUNT_DASHBOARD]: {
    [ACCOUNT_DASHBOARD_SUB_MODULE_MAP.MY_ACCOUNT_DETAILS]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW_MODIFY,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [LOANER_MANAGER]: ACTION_VIEW,
    },
    [ACCOUNT_DASHBOARD_SUB_MODULE_MAP.BUILDINGS]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.MODIFY],
      [STUDENT]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [SUPER_STUDENT]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [LOANER_MANAGER]: ACTION_VIEW,
      [PRACTICE_STUDENT]: ACTION_VIEW,
    },
    [ACCOUNT_DASHBOARD_SUB_MODULE_MAP.ACCOUNT_CONTACTS]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: [ROLE_ACTIONS.VIEW],
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [SUPER_STUDENT]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [LOANER_MANAGER]: ACTION_VIEW,
    },
    [ACCOUNT_DASHBOARD_SUB_MODULE_MAP.ADDRESSES]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: [ROLE_ACTIONS.VIEW, ROLE_ACTIONS.MODIFY],
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [SUPER_STUDENT]: ACTION_VIEW,
      [ADVANCED_STUDENT]: ACTION_VIEW,
      [STUDENT_REPAIR_TECHNICIAN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [LOANER_MANAGER]: ACTION_VIEW,
    },
    [ACCOUNT_DASHBOARD_SUB_MODULE_MAP.ACCOUNT_CREDITS]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [LOANER_MANAGER]: ACTION_VIEW,
    },
    [ACCOUNT_DASHBOARD_SUB_MODULE_MAP.OPEN_POS]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [REPAIR_TECH]: ACTION_VIEW,
      [FACILITATOR]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
    },
    [ACCOUNT_DASHBOARD_SUB_MODULE_MAP.CHANGE_IN_HOUSE_REPAIR]: {
      [VIVACITY_ADMIN]: ALL_ACTIONS,
    },
    [ACCOUNT_DASHBOARD_SUB_MODULE_MAP.SERVICE_ACCOUNT]: {
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [SUPER_ADMIN]: ALL_ACTIONS,
    },
    [ACCOUNT_DASHBOARD_SUB_MODULE_MAP.PAID_TIER]: {
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [SUPER_ADMIN]: ALL_ACTIONS,
    },
  },
  [PAGE_NAMES.SIDEBAR]: {
    [SIDEBAR_SUB_MODULE_MAP.FAVORITE]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [STUDENT]: ALL_ACTIONS,
      [PRACTICE_STUDENT]: ALL_ACTIONS,
      [STUDENT_REPAIR_TECHNICIAN]: ALL_ACTIONS,
      [SUPER_STUDENT]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ALL_ACTIONS,
      [REPAIR_TECH]: ALL_ACTIONS,
      [FACILITATOR]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [LOANER_MANAGER]: ALL_ACTIONS,
    },
    [SIDEBAR_SUB_MODULE_MAP.DASHBOARD_COLOR]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [LOANER_MANAGER]: ALL_ACTIONS,
    },
  },
  [PAGE_NAMES.INVOICES]: {
    [INVOICES_SUB_MODULE_MAP.INVOICES]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
    },
    [INVOICES_SUB_MODULE_MAP.ORDERS]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
    },
    [INVOICES_SUB_MODULE_MAP.QUOTES]: {
      [SUPER_ADMIN]: ACTION_VIEW,
      [SCHOOL_DISTRICT_ADMIN]: ACTION_VIEW,
      [BUILDING_ADMIN]: ACTION_VIEW,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
    },
  },
  [PAGE_NAMES.HEADER_MENU]: {
    [HEADER_MENU_SUB_MODULE_MAP.NOTIFICATIONS]: {
      [SUPER_ADMIN]: ALL_ACTIONS,
      [SCHOOL_DISTRICT_ADMIN]: ALL_ACTIONS,
      [BUILDING_ADMIN]: ALL_ACTIONS,
      [VIVACITY_ADMIN]: ALL_ACTIONS,
      [REPAIR_TECH]: ALL_ACTIONS,
      [FACILITATOR]: ALL_ACTIONS,
      [LOANER_MANAGER]: ALL_ACTIONS,
    },
  },
};

export {
  ROLE_ACTIONS,
  REPAIR_360_SUB_MODULE_MAP,
  USER_SUB_MODULE_MAP,
  RESOURCES_SUB_MODULE_MAP,
  REPORT_CENTER_SUB_MODULE_MAP,
  MY_PROFILE_SUB_MODULE_MAP,
  MANAGE_DEVICES_SUB_MODULE_MAP,
  FEATURES_SUB_MODULE_MAP,
  SUPPORT_SUB_MODULE_MAP,
  ACCOUNT_DASHBOARD_SUB_MODULE_MAP,
  SIDEBAR_SUB_MODULE_MAP,
  INVOICES_SUB_MODULE_MAP,
  HEADER_MENU_SUB_MODULE_MAP,
};
