export const REPAIR_360_SUB_MODULE_MAP = {
  IN_HOUSE_REPAIR_REPORTS: 'In house Repair Reports',
  REPAIR_CENTER: 'Repair Center',
  STUDENT_REPAIR_CENTER: 'Student Repair Center',
  REPAIR_BOXES: 'Repair Boxes',
  REPAIR_TICKETS: 'Repair Tickets',
  PART_CLOSET: 'Parts Closet',
};

export const USER_SUB_MODULE_MAP = {
  ORGANIZATIONAL_UNITS: 'Organizational Units',
  USERS: 'Users',
  BULK_USER_ACTIONS: 'Bulk User Actions',
};

export const RESOURCES_SUB_MODULE_MAP = {
  INSTRUCTIONS: 'Instructions',
  HOW_TO_VIDEOS: 'How to Videos',
  WARRANTY_INFO: 'Warranty Info',
};

export const REPORT_CENTER_SUB_MODULE_MAP = {
  VIVACITY_REPAIR_REPORT: 'Vivacity Repair Report',
  IN_HOUSE_REPAIR_REPORTS: 'In house Repair Reports',
  DEVICE_REPAIR_HISTORY_REPORT: 'Device Repair History Report',
  SRA_REPORTS: 'SRA Reports',
  NON_WARRANTY_REPAIRS: 'Non Warranty Repairs',
  TELEMETRY_DATA: 'Telemetry Data',
  FLEET_VALUE: 'Fleet Value',
};

export const INVOICES_SUB_MODULE_MAP = {
  QUOTES: 'Quotes',
  ORDERS: 'Orders',
  INVOICES: 'Invoices',
};

export const MY_BUILDINGS_SUB_MODULE_MAP = {
  BUILDINGS: 'Buildings',
};

export const MY_PROFILE_SUB_MODULE_MAP = {
  PROFILE_DETAILS: 'Profile Details',
  ACCOUNT: 'Account',
  IMAGE: 'Image',
  NOTIFICATIONS: 'Notifications',
  PREFERENCES: 'Preferences',
};

export const MANAGE_DEVICES_SUB_MODULE_MAP = {
  MY_FLEET: 'My Fleet',
  LOANER_MANAGEMENT: 'Loaner Management',
  IMPORT_SERIAL_DEVICES: 'Import Serial Devices',
  ORGANIZATION_UPLOADED_ASSETS: 'Organization Uploaded Assets',
  MY_FLEET_CREATE_TICKET_360: 'My Fleet Create Ticket 360',
};

export const FEATURES_SUB_MODULE_MAP = {
  UNDER_CONSIDERATION: 'Under Consideration',
  SCHEDULED: 'Scheduled',
  UNDER_CONSTRUCTION: 'Under Construction',
  LAUNCHED: 'Launched',
  FEATURE_REQUEST: 'Feature Request',
};

export const SUPPORT_SUB_MODULE_MAP = {
  MAIN_PAGE: 'main_page',
};

export const ACCOUNT_DASHBOARD_SUB_MODULE_MAP = {
  MY_ACCOUNT_DETAILS: 'My Account Details',
  SERVICE_ACCOUNT: 'Service Account',
  BUILDINGS: 'Buildings',
  ACCOUNT_CONTACTS: 'Account Contacts',
  ADDRESSES: 'Addresses',
  ACCOUNT_CREDITS: 'Account Credits',
  OPEN_POS: 'Open POS',
  CHANGE_IN_HOUSE_REPAIR: 'Toggle_In_House_Repair',
  PAID_TIER: 'PAID_TIER',
};

export const SIDEBAR_SUB_MODULE_MAP = {
  FAVORITE: 'Favorite',
  DASHBOARD_COLOR: 'Dashboard Color',
};

export const HEADER_MENU_SUB_MODULE_MAP = {
  NOTIFICATIONS: 'NOTIFICATIONS',
};
