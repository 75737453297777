import { Button, Box, darken, styled, useTheme } from '@mui/material';
import COLORS from 'src/constants/colors/colors.constant';
import { getLuminance, augmentSaturation } from 'src/utils/colorConversion';
import { SecondaryButtonProps } from './SecondaryButton';

export const FixColorSaturation = () => {
  const theme = useTheme();
  const secondaryBackgroundColor = theme.palette.secondary.main;
  const textColorSaturation = augmentSaturation(secondaryBackgroundColor, 100);
  const luminance = getLuminance(secondaryBackgroundColor);
  let textColor;
  let hoverTextColor;
  let hoverBgColor = darken(secondaryBackgroundColor, 0.05);

  if (luminance < 0.5) {
    textColor = COLORS.primary.textDark;
    hoverBgColor = darken(secondaryBackgroundColor, 0.2);
  } else {
    textColor = darken(textColorSaturation, 0.5);
    hoverTextColor = darken(textColor, 0.1);
  }

  return { textColor, hoverTextColor, hoverBgColor };
};

export const SecondaryButtonCore = styled(Button)<SecondaryButtonProps>(
  ({ theme, disabledStyle, disabledCompletely }) => {
    const secondaryBackgroundColor = theme.palette.secondary.main;
    const { textColor, hoverTextColor, hoverBgColor } = FixColorSaturation();
    return {
      fontWeight: 'bold',
      padding: '8px 16px',
      color: disabledStyle ? COLORS.primary.disabledGrey : textColor,
      backgroundColor: disabledStyle ? COLORS.primary.disabledGrey : secondaryBackgroundColor,
      borderRadius: theme.spacing(1),
      textTransform: 'none',
      pointerEvents: disabledCompletely ? 'none' : 'auto',
      opacity: disabledCompletely ? 0.6 : 1,
      gap: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '&:hover': {
        backgroundColor: disabledStyle ? COLORS.primary.disabledGrey : hoverBgColor,
        color: disabledStyle ? COLORS.primary.disabledGrey : hoverTextColor,
      },
      '&.MuiButton-root:disabled': {
        backgroundColor: COLORS.primary.disabledGrey,
        pointerEvents: 'auto',
      },
    };
  }
);

export const YellowDot = styled(Box)(() => ({
  width: '10px',
  height: '10px',
  backgroundColor: '#ffd500',
  borderRadius: '50%',
  position: 'absolute',
  top: '5px',
  right: '5px',
}));
